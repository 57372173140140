
import { defineComponent } from "vue";
import Icon from "@/components/ui/Icon.vue";

export default defineComponent({
  name: "ExpandButton",
  components: { Icon },
  props: {
    modelValue: {
      type: Boolean,
    },
  },
  methods: {
    handleClick(): void {
      this.$emit("update:modelValue", !this.modelValue);
    },
  },
});
